body,
html,
#root {
  height: 100%;
  background-color: #f3f6f9;
}

/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/liffey.jpg");

  /* Set a specific height 
  height: 80%;*/

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  color: white;
  animation-name: fadeIn;
  animation-duration: 2s;
}

.logo-animation {
  animation-name: fadeIn;
  animation-duration: 2s;
}

.image-stack {
  max-width: 100%;
  height: auto;
}

.info-card {
  display: "flex";
  justify-content: "flex-start";
  flex-direction: "column";
  animation-name: fadeIn;
  animation-duration: 2s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.logo-image {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.container-wrapper {
  padding: 40px;
}

.MuiSvgIcon-root.center-svg {
  display: block;
  margin: auto;
  border-radius: 50%;
  background-color: #59617d;
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.portfolio-image {
  display: block;
  width: 100%;
  height: auto;
  max-height: 300px;
}

.portfolio-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.portfolio-container:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
